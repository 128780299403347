<script setup>
import { mapErrors } from "@/utilities";
import { useForm } from "@inertiajs/vue3";
import { computed, inject } from "vue";
import Button from "@/Components/Button/Button.vue";
import Form from "./Partials/Form.vue";
import LocationLayout from "@/Layouts/LocationLayout.vue";
import saveIcon from "@i/save-white.svg";

const route = inject("route");
const dayjs = inject("dayjs");

const props = defineProps({
  shift: Object,
  multiShifts: Array,
  directApprovalConditionsOptions: Object,
  positionOptions: Object,
  dresscodeOptions: Object,
  shiftCountsByDate: Object
});

// Transforming the data into multiData format
const multiData = props.multiShifts.map(multiShift => ({
  start_date: dayjs(multiShift.start).format("YYYY-MM-DD"),
  end_date: dayjs(multiShift.end).format("YYYY-MM-DD"),
  start_time: dayjs(multiShift.start).format("H:mm"),
  end_time: dayjs(multiShift.end).format("H:mm"),
  break: multiShift.break.toString() // Assuming `break` is an integer in the provided data
}));

const form = useForm({
  multiData,
  position_id: "",
  description: "",
  number_of_shifts: 1,
  travel_reimbursement: false,
  visible_to_freelancers: false,
  freelancer_hourly_wage: 1400,
  direct_approval_conditions: [],
  dresscode: "",
  ...props.shift,
  additional_wage: props.shift.additional_wage ?? 0
});

const sendForm = () => {
  form
    .transform(data => ({
      ...data,
      multiData: data.multiData.map(item => ({
        ...item,
        start: dayjs(item.start_date + " " + item.start_time).unix(),
        end: dayjs(item.start_date + " " + item.end_time).unix(),
        startDb: dayjs(item.start_date + " " + item.start_time).unix(),
        endDb: dayjs(item.end_date + " " + item.end_time).unix()
      })),
      userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }))
    .put(route("location.shifts.update", { shift: props.shift.id }), {
      // preserveState: true,
      preserveScroll: true,
      onError: errors => (form.errors = mapErrors(errors))
    });
};
</script>

<template>
  <LocationLayout>
    <div class="text-blue">
      <h1 class="font-bold text-[32px] mb-2">{{ $t('Add shift') }}</h1>

      <FormKit type="form" id="ShiftForm" @submit="sendForm" :actions="false">
        <Form
          :form="form"
          :positionOptions="positionOptions"
          :dresscodeOptions="dresscodeOptions"
          :directApprovalConditionsOptions="directApprovalConditionsOptions"
        >
          <div class="flex flex-col justify-end h-full">
            <div class="flex justify-end">
              <Button
                orange
                type="button"
                :processing="form.processing"
                :recentlySuccessful="form.recentlySuccessful"
                :text="$t('Save')"
                :icon="saveIcon"
                @click="sendForm"
              />
            </div>
          </div>
        </Form>
      </FormKit>
    </div>
  </LocationLayout>
</template>
